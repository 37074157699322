import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'



axios.defaults.baseURL = 'https://paimef-transparente.herokuapp.com/api'

createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios,axios)
    .use(VueChartkick)
    .mount('#app')
