<template>

    <div class="dotsHeader">
        <div class="dot-1 space"></div>
        <div class="dot-2 space"></div>
        <div class="dot-3 space"></div>
        <div class="dot-4 space"></div>
        <div class="dot-5 space"></div>
        <div class="dot-6 space"></div>
        <div class="dot-7 space"></div>
        <div class="dot-8 space"></div>
        <div class="dot-9 space"></div>
        <div class="dot-10 space"></div>
        <div class="dot-11 space"></div>
        <div class="dot-12 space"></div>
        <div class="dot-13 space"></div>
        <div class="dot-14 space"></div>
        <div class="dot-15"></div>
    </div>


</template>

<script>
export default {
    name:'Dots'
}
</script>



<style>

@media only screen and (max-width: 767px) {

    .dotsHeader{
        width:80%;
        margin:10px auto;
        display:flex; justify-content:center; align-items:center;
    }
    .dot-1{
        width:5px;
        height:5px;
        background-color:#7357c1;
        border-radius:50%;
    }
     .dot-2{
        width:5px;
        height:5px;
        background-color:#5c8cda;
        border-radius:50%;
    }
     .dot-3{
        width:5px;
        height:5px;
        background-color:#74bdac;
        border-radius:50%;
    }
     .dot-4{
        width:5px;
        height:5px;
        background-color:#767a51;
        border-radius:50%;
    }
     .dot-5{
        width:5px;
        height:5px;
        background-color:#c9a265;
        border-radius:50%;
    }
     .dot-6{
        width:5px;
        height:5px;
        background-color:#d2770f;
        border-radius:50%;
    }
     .dot-7{
        width:5px;
        height:5px;
        background-color:#a83e37;
        border-radius:50%;
    }
     .dot-8{
        width:5px;
        height:5px;
        background-color:#dd7495;
        border-radius:50%;
    }
     .dot-9{
        width:5px;
        height:5px;
        background-color:#71285d;
        border-radius:50%;
    }
     .dot-10{
        width:5px;
        height:5px;
        background-color:#003891;
        border-radius:50%;
    }
     .dot-11{
        width:5px;
        height:5px;
        background-color:#4eba74;
        border-radius:50%;
    }
     .dot-12{
        width:5px;
        height:5px;
        background-color:#dbdc76;
        border-radius:50%;
    }
     .dot-13{
        width:5px;
        height:5px;
        background-color:#da961d;
        border-radius:50%;
    }
     .dot-14{
        width:5px;
        height:5px;
        background-color:#be111a;
        border-radius:50%;
    }
     .dot-15{
        width:5px;
        height:5px;
        background-color:#201914;
        border-radius:50%;
    }

}

@media only screen and (min-width: 768px) and (max-width:1023px) {

    .dotsHeader{
        width:80%;
        margin:10px auto;
        display:flex; justify-content:center; align-items:center;
    }
    .dot-1{
        width:5px;
        height:5px;
        background-color:#7357c1;
        border-radius:50%;
    }
     .dot-2{
        width:5px;
        height:5px;
        background-color:#5c8cda;
        border-radius:50%;
    }
     .dot-3{
        width:5px;
        height:5px;
        background-color:#74bdac;
        border-radius:50%;
    }
     .dot-4{
        width:5px;
        height:5px;
        background-color:#767a51;
        border-radius:50%;
    }
     .dot-5{
        width:5px;
        height:5px;
        background-color:#c9a265;
        border-radius:50%;
    }
     .dot-6{
        width:5px;
        height:5px;
        background-color:#d2770f;
        border-radius:50%;
    }
     .dot-7{
        width:5px;
        height:5px;
        background-color:#a83e37;
        border-radius:50%;
    }
     .dot-8{
        width:5px;
        height:5px;
        background-color:#dd7495;
        border-radius:50%;
    }
     .dot-9{
        width:5px;
        height:5px;
        background-color:#71285d;
        border-radius:50%;
    }
     .dot-10{
        width:5px;
        height:5px;
        background-color:#003891;
        border-radius:50%;
    }
     .dot-11{
        width:5px;
        height:5px;
        background-color:#4eba74;
        border-radius:50%;
    }
     .dot-12{
        width:5px;
        height:5px;
        background-color:#dbdc76;
        border-radius:50%;
    }
     .dot-13{
        width:5px;
        height:5px;
        background-color:#da961d;
        border-radius:50%;
    }
     .dot-14{
        width:5px;
        height:5px;
        background-color:#be111a;
        border-radius:50%;
    }
     .dot-15{
        width:5px;
        height:5px;
        background-color:#201914;
        border-radius:50%;
    }

}

@media only screen and (min-width: 1024px) {

    .dotsHeader{
        width:100%;
        margin:10px auto;
        display:flex; justify-content:center; align-items:center;
    }
    .dot-1{
        width:10px;
        height:10px;
        background-color:#7357c1;
        border-radius:50%;
    }
     .dot-2{
        width:10px;
        height:10px;
        background-color:#5c8cda;
        border-radius:50%;
    }
     .dot-3{
        width:10px;
        height:10px;
        background-color:#74bdac;
        border-radius:50%;
    }
     .dot-4{
        width:10px;
        height:10px;
        background-color:#767a51;
        border-radius:50%;
    }
     .dot-5{
        width:10px;
        height:10px;
        background-color:#c9a265;
        border-radius:50%;
    }
     .dot-6{
        width:10px;
        height:10px;
        background-color:#d2770f;
        border-radius:50%;
    }
     .dot-7{
        width:10px;
        height:10px;
        background-color:#a83e37;
        border-radius:50%;
    }
     .dot-8{
        width:10px;
        height:10px;
        background-color:#dd7495;
        border-radius:50%;
    }
     .dot-9{
        width:10px;
        height:10px;
        background-color:#71285d;
        border-radius:50%;
    }
     .dot-10{
        width:10px;
        height:10px;
        background-color:#003891;
        border-radius:50%;
    }
     .dot-11{
        width:10px;
        height:10px;
        background-color:#4eba74;
        border-radius:50%;
    }
     .dot-12{
        width:10px;
        height:10px;
        background-color:#dbdc76;
        border-radius:50%;
    }
     .dot-13{
        width:10px;
        height:10px;
        background-color:#da961d;
        border-radius:50%;
    }
     .dot-14{
        width:10px;
        height:10px;
        background-color:#be111a;
        border-radius:50%;
    }
     .dot-15{
        width:10px;
        height:10px;
        background-color:#201914;
        border-radius:50%;
    }
    .space{
        margin-right:10px;
    }

}

</style>