<template>
  <div class="barDorada"></div>
  <div class="barMorada"></div>
  <div class="contenedorPrincipal">
    <div class="contenedorHeader">
        <div class="headerPrincipal">
          <h1>PAIMEF TRANSPARENTE</h1>
          <h4>Plataforma de Transparencia del Programa de Apoyo a las Instancias de Mujeres en las Entidades Federativas</h4>
          <p>#VidaLibreDeViolencia</p>
        </div>
        <div class="dots">
            <Dots></Dots>
        </div>
    
        <div class="headerSecundario">
          <p>"Este programa es público, ajeno a cualquier partido político. Queda prohibido el uso para fines distintos a los establecidos en el programa."</p>
        </div>
    </div>

    <div class="resultadoContenedor">
      <h1>Resultados</h1>
      <div class="contenedorGrafica">
        <div class="periodoGrafica">
          <Grafica
            titulo="Gráfica Vertiente A | Profesionalización"
            va=11060730.82
            vb=11183671.02
            vc=12187005.35
            
          />
        </div>
        <div class="periodoGrafica">
          <Grafica
            titulo="Gráfica Vertiente B | Prevención"
            va=29322151.77
            vb=30505352.84
            vc=20500025.18
            
          />
        </div>
        <div class="periodoGrafica">
          <Grafica
            titulo="Gráfica Vertiente C | Atención"
            va=157003036.78
            vb=149429601.01
            vc=167241775.35
            
          />
        </div>
      </div>
    </div>

    <div class="tituloPrincipal">
      <h1>Mapa Interactivo</h1>
    </div>
    
    <div class="contenedorMapaInfo">
      
      <div class="contMapa">
        <h1>Mapa</h1>
        <Mexico
          @mostrarEstado="estados($event)"
        />
      </div>
      <div class="contInformacion">
          <h1>Informacion</h1>
          <div class="showInformacion">
            <h1>{{ nombreEstado }}</h1>
            <div class="mostrarDatos" v-if="nombreEstado !== null">
              <div class="dataInfo"
               v-for="( {  gastoTotal ,periodo, totalVa, totalVb, totalVc }, index ) in estadoGeneral" :key="index">
                  <table class="tablaResultados">
                    <tr>
                      <th>Periodo</th>
                      <th>Gasto Total</th>
                      <th>Profesionalización</th>
                      <th>Prevención</th>
                      <th>Atención</th>
                    </tr>
                    <tr>
                      <td class="dato">{{ periodo }}</td>
                      <td class="dato">$ {{gastoTotal}} </td>
                      <td class="dato">$ {{ totalVa }}</td>
                      <td class="dato">$ {{ totalVb }}</td>
                      <td class="dato">$ {{ totalVc }}</td>
                    </tr>
                  </table>
              </div>
              <a :href="sitioWeb" target="_blank" v-if="mostrarWeb">Visita su sitio web</a>
            </div>
            <div class="mensajeError" v-if="mostrarMensaje">
              <h2>No hay datos disponible</h2>
              <p>No se ha capturado información</p>
            </div>
            
          </div>
      </div>
    </div>
  </div>
  

</template>

<script>

import Dots from '@/components/Dots.vue'
import Mexico from '@/components/Mexico.vue'
import Grafica from '@/components/Grafica.vue'



export default {
  components: {
    Dots,
    Mexico,
    Grafica
  },
  data(){
    return {
      nombreEstado: null,
      estadoGeneral: [],
      mostrarMensaje: false,
      mostrarWeb: false,
      sitioWeb: '',
      paginas: [
        { nombre:'Puebla',
          pagina: 'http://puebla.paimeftransparente.com/'
        },
        { nombre:'Colima',
          pagina: 'http://colima.paimeftransparente.com/'
        },
        { nombre:'Jalisco',
          pagina: 'http://jalisco.paimeftransparente.com/'
        },
        { nombre:'Guanajuato',
          pagina: 'http://guanajuato.paimeftransparente.com/'
        },
        { nombre:'Querétaro',
          pagina: 'http://queretaro.paimeftransparente.com/'
        },
        { nombre:'Yucatán',
          pagina: 'http://yucatan.paimeftransparente.com/'
        },
        { nombre:'San Luis Potosí',
          pagina: 'http://sanluispotosi.paimeftransparente.com/'
        },
        { nombre:'Aguascalientes',
          pagina: 'http://aguascalientes.paimeftransparente.com/'
        },
        { nombre:'Tamaulipas',
          pagina: 'http://tamaulipas.paimeftransparente.com/'
        },
        { nombre:'Durango',
          pagina: 'http://durango.paimeftransparente.com/'
        },
        { nombre:'Nuevo León',
          pagina: 'http://nuevoleon.paimeftransparente.com/'
        },
        { nombre:'Coahuila',
          pagina: 'http://coahuila.paimeftransparente.com/'
        },
        { nombre:'Sinaloa',
          pagina: 'http://sinaloa.paimeftransparente.com/'
        },
        { nombre:'Chihuahua',
          pagina: 'http://chihuahua.paimeftransparente.com/'
        },
        { nombre:'Chiapas',
          pagina: 'http://chiapas.paimeftransparente.com/'
        },
        { nombre:'Oaxaca',
          pagina: 'http://oaxaca.paimeftransparente.com/'
        },
        { nombre:'Guerrero',
          pagina: 'http://guerrero.paimeftransparente.com/'
        },
        { nombre:'Tabasco',
          pagina: 'http://tabasco.paimeftransparente.com/'
        },
        { nombre:'Michoacán',
          pagina: 'http://michoacan.paimeftransparente.com/'
        },
        { nombre:'Morelos',
          pagina: 'http://morelos.paimeftransparente.com/'
        },
        { nombre:'Estado de México',
          pagina: 'http://estadodemexico.paimeftransparente.com/'
        },
        { nombre:'Campeche',
          pagina: 'http://campeche.paimeftransparente.com/'
        },
        { nombre:'CDMX',
          pagina: 'http://cdmx.paimeftransparente.com/'
        },
        { nombre:'Tlaxcala',
          pagina: 'http://tlaxcala.paimeftransparente.com/'
        },
        { nombre:'Hidalgo',
          pagina: 'http://hidalgo.paimeftransparente.com/'
        },
        { nombre:'Zacatecas',
          pagina: 'http://zacatecas.paimeftransparente.com/'
        },
        { nombre:'Veracruz',
          pagina: 'http://veracruz.paimeftransparente.com/'
        },
        { nombre:'Quintana Roo',
          pagina: 'http://quintanaroo.paimeftransparente.com/'
        },
        { nombre:'Nayarit',
          pagina: 'http://nayarit.paimeftransparente.com/'
        },
        { nombre:'Baja California Sur',
          pagina: 'http://bajacaliforniasur.paimeftransparente.com/'
        },
        { nombre:'Baja California',
          pagina: 'http://bajacalifornia.paimeftransparente.com/'
        },
        { nombre:'Sonora',
          pagina: 'http://sonora.paimeftransparente.com/'
        }
      ],
      data: {
        va2018: '',
        va2019: '',
        va2020: '',
        vb2018: '',
        vb2019: '',
        vb2020: '',
        vc2018: '',
        vc2019: '',
        vc2020: ''
      }
      
    }
  },
  created(){
    this.traerData()
  },
  methods:{
    estados(nombre){
      this.estadoGeneral = []
      this.mostrarMensaje = false
      this.nombreEstado = nombre
      this.dataGeneral( nombre )
    },
    async dataGeneral(nombre){
      
      await this.axios.get('/mostrar-general')
        .then( result => {
          const data = result.data;
          const estado = data.filter( dato => dato.estado === nombre )
          if(estado.length !== 0){
            this.estadoGeneral = estado;
          }else{
            this.mostrarMensaje = true
          }
        })
        .catch( err => {
          console.log( 'Error inesperado', err )
        })

        this.buscarWeb( nombre )

    },
    buscarWeb( estado ){
      const resultado = this.paginas.find( r => r.nombre === estado )
       this.sitioWeb = resultado.pagina
       this.mostrarWeb = true
    },
    async traerData(){

      await this.axios.get('/mostrar-general')
        .then( result => {
          const data = result.data;
          const  periodo2018  = this.filtrarVertiente( data, '2018' )
          const  periodo2019  = this.filtrarVertiente( data, '2019' )

          const  periodo2020  = this.filtrarVertiente( data, '2020' )
          



          const sumaVa2018 = this.sumatoriaVa( periodo2018 )
          const sumaVa2019 = this.sumatoriaVa( periodo2019 )
          const sumaVa2020 = this.sumatoriaVa( periodo2020 )

          const sumaVb2018 = this.sumatoriaVb( periodo2018 )
          const sumaVb2019 = this.sumatoriaVb( periodo2019 )
          const sumaVb2020 = this.sumatoriaVb( periodo2020 )
          console.log( sumaVb2020 );
          

          const sumaVc2018 = this.sumatoriaVc( periodo2018 )
          const sumaVc2019 = this.sumatoriaVc( periodo2019 )
          const sumaVc2020 = this.sumatoriaVc( periodo2020 )
          
         
        })
        .catch( err => {
          console.log( 'Error inesperado', err )
        })
    },
    filtrarVertiente(data, periodo){

        return data.filter( dato => dato.periodo === periodo ) 
    },
    sumatoriaVa( valor ){
      let resultado = 0
      for (let index = 0; index < valor.length; index++) {
        
         resultado = resultado + valor[index].totalVa
      }

      return resultado
    },
    sumatoriaVb( valor ){
      let resultado = 0
      for (let index = 0; index < valor.length; index++) {
        
         resultado = resultado + valor[index].totalVb
      }

      return resultado
    },
    sumatoriaVc( valor ){
      let resultado = 0
      for (let index = 0; index < valor.length; index++) {
        
         resultado = resultado + valor[index].totalVc
      }

      return resultado
    }
    
  }
}
</script>

<style >

  .barMorada{
    width:100%;
    height:20px;
    background-color: #392f5c;
  }
  .barDorada{
    width:100%;
    height:20px;
    background-color: #d2770f;
  }
  .contenedorPrincipal{
    width:100%;
  }
  .contMapa{
    width:50%;
  }
  .contInformacion{
    width:50%;
  }
  .contenedorPrincipal{
    width:100%;
  }
  .contenedorHeader{
    width:100%;
  }
  .headerPrincipal{
    width:85%;
    margin:20px auto;
    text-align:center;
  }
  .headerPrincipal h1{
    font-size:50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align:center;
    color: #4d456a;
  }
  .headerPrincipal h4{
    width:70%;
    margin:10px auto;
    font-size:18px;
    font-family: 'Montserrat', sans-serif;
    text-align:center;
    color: #4d456a;
  }
  .headerPrincipal p{
    font-size:16px;
    font-family: 'Montserrat', sans-serif;
    color: #4d456a;
  }

  .headerSecundario{
    width:60%;
    margin:20px auto;
    text-align:center; 
  }
  .headerSecundario p {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight:500;
    color: #4d456a;
  }
  .headerSecundario h1 {
    font-size:45px;
    font-family: 'Montserrat', sans-serif;
    color: #4d456a;
    font-weight: 700;
  }
  .dots{
    width:100%;
  }
  .tituloPrincipal{
    width:80%;
    margin:100px auto;
    text-align:center;
    
    
  }
  
  .contenedorMapaInfo{
    width:90%;
    display:flex; justify-content:center;
    height:100vh;
    margin:20px auto;
  }
  .contenedorMapaInfo h1{
    width:100%;
    margin:20px auto;
  }

  .contMapa{
    width:60%;
    
    text-align:center;
    
    
  }
  .contInformacion{
    width:40%;
    text-align:center;
  }
  .showInformacion h3{
    margin:0;
  }
  .dataInfo{
    width:100%;
    display:flex; justify-content:space-between;
    flex-direction: column;
    margin:20px auto; 
  }
  .dataVertiente{
    width:100%;
    display:flex; justify-content: space-between;
  }
  .tablaResultados{
    width:100%;
    font-size:14px;
    margin:10px;
    text-align:center;
  }
  .dato{
    font-size:14px;
    font-weight: bold;
  }
  .resultadoContenedor{
    width:90%;
    margin:10px auto;
    text-align:center;
    
  }
  .contenedorGrafica{
    width:100%;
    display:flex;
  }
  .periodoGrafica{
    width:33%;
  }
  
  
</style>
