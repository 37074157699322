<template>
    <div class="grafica">
        <h5>{{ titulo }}</h5>
        <column-chart
            :data="[['2018',va],['2019',vb],['2020',vc]]"
            :color ="['#620096','#56008c','#4b0081']"
        />
    </div>
</template>

<script>
export default {
    props:{
        va: {type: Number,default: 0 },
        vb: { type: Number, default: 0 },
        vc: { type: Number, default: 0 },
        titulo: String
        
    }
}
</script>

<style>

</style>