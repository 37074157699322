<template>
  <router-view/>
</template>

<style>
  body{
    padding:0;
    margin:0;
    box-sizing: border-box;
    width:100vw;
    height:100vh;
    background-color: #f1f1f1;
    font-family: 'Montserrat', sans-serif;
  }
</style>
